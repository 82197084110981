// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-auth-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/auth.jsx" /* webpackChunkName: "component---src-pages-auth-jsx" */),
  "component---src-pages-authenticate-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/authenticate.jsx" /* webpackChunkName: "component---src-pages-authenticate-jsx" */),
  "component---src-pages-confirm-account-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/confirmAccount.jsx" /* webpackChunkName: "component---src-pages-confirm-account-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-licenses-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/licenses.jsx" /* webpackChunkName: "component---src-pages-licenses-jsx" */),
  "component---src-pages-page-2-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-signup-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-wizard-company-information-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/CompanyInformation/index.jsx" /* webpackChunkName: "component---src-pages-wizard-company-information-index-jsx" */),
  "component---src-pages-wizard-complete-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Complete/index.jsx" /* webpackChunkName: "component---src-pages-wizard-complete-index-jsx" */),
  "component---src-pages-wizard-delivery-vehicles-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/DeliveryVehicles/index.jsx" /* webpackChunkName: "component---src-pages-wizard-delivery-vehicles-index-jsx" */),
  "component---src-pages-wizard-employees-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Employees/index.jsx" /* webpackChunkName: "component---src-pages-wizard-employees-index-jsx" */),
  "component---src-pages-wizard-inventory-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Inventory/index.jsx" /* webpackChunkName: "component---src-pages-wizard-inventory-index-jsx" */),
  "component---src-pages-wizard-plant-inventory-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/PlantInventory/index.jsx" /* webpackChunkName: "component---src-pages-wizard-plant-inventory-index-jsx" */),
  "component---src-pages-wizard-plants-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Plants/index.jsx" /* webpackChunkName: "component---src-pages-wizard-plants-index-jsx" */),
  "component---src-pages-wizard-printers-and-labels-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/PrintersAndLabels/index.jsx" /* webpackChunkName: "component---src-pages-wizard-printers-and-labels-index-jsx" */),
  "component---src-pages-wizard-resume-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Resume/index.jsx" /* webpackChunkName: "component---src-pages-wizard-resume-index-jsx" */),
  "component---src-pages-wizard-resume-ready-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Resume/ready.jsx" /* webpackChunkName: "component---src-pages-wizard-resume-ready-jsx" */),
  "component---src-pages-wizard-rooms-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Rooms/index.jsx" /* webpackChunkName: "component---src-pages-wizard-rooms-index-jsx" */),
  "component---src-pages-wizard-strains-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/Wizard/Strains/index.jsx" /* webpackChunkName: "component---src-pages-wizard-strains-index-jsx" */)
}

