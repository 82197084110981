import { getUser, isImpersonatingUser, logout } from './auth';
import { useGlobal, useState } from 'reactn';

import Axios from 'axios';
import { FORM_NAME as INTRO_FORM_NAME } from './introInformation';
import { between } from './criteria';
import { userDataInitialized } from '../events';
import uuid4 from 'uuid4';

export const getFormStateLoaded = () => {
  const [formStateLoaded] = useGlobal('formStateLoaded');
  return formStateLoaded;
};

export const initialGlobalState = () => ({
  formState: {},
  formStateLoaded: false,
  impersonatedFormState: {},
});

let initialized = false;

export const initializeState = () => {
  const [, setGlobal] = useGlobal();

  if (initialized) return;

  setGlobal(initialGlobalState());

  const user = getUser();

  if (!user) return;

  // Initialized indicates we've made an effort to reload state
  initialized = true;

  (async () => {
    try {
      const res = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
        operationName: null,
        variables: {},
        query: `{ getFormState(idToken: "${user.idToken}") }`,
      });

      const data = JSON.parse(res.data.data.getFormState);

      if (data) {
        setGlobal({ ...initialGlobalState(), formState: data, formStateLoaded: true });
        const userId_ = data.introInformation ? data.introInformation.email : null;
        if (userId_) {
          userDataInitialized({ userId_ });
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'Logging the user out due to getting no data for them from backend'
        );
        logout();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      logout();
    }
  })();
};

export const getRawFormState = (useImpersonationState = true) => {
  const isImpersonating = isImpersonatingUser();
  const [formState = {}, setFormState] = useGlobal('formState');
  const [impersonatedFormState = {}, setImpersonatedFormState] = useGlobal(
    'impersonatedFormState'
  );

  return {
    formState:
      isImpersonating && useImpersonationState ? impersonatedFormState : formState,
    setFormState: isImpersonating ? setImpersonatedFormState : setFormState,
  };
};

export const getFormState = (formName, useImpersonationState = true) => {
  const { formState, setFormState } = getRawFormState(useImpersonationState);
  const [working, setWorking] = useState(false);

  return {
    formState,
    setFormState,
    formName,
    values: formState[formName] || {},
    working,
    setWorking,
  };
};

const userIdForDataUpdate = update => {
  const user = getUser();

  const userId = !!user
    ? isImpersonatingUser()
      ? user.impersonatedUser
      : user.name
    : update[INTRO_FORM_NAME].email;

  return userId;
};

export const allState = () => ({ formState: getRawFormState().formState });

export const handleFormSubmit = formStateRef => (values, multi = true) => {
  const { formName, formState, setFormState, setWorking } = formStateRef;
  const update = { ...formState };

  if (multi) {
    if (!update[formName]) {
      update[formName] = {};
    }

    if (!!values) {
      if (!values.id) {
        values.id = uuid4();
      }
      values.updatedAt = Date.now();
      update[formName][values.id] = values;
    }
  } else if (!!values && !multi) {
    update[formName] = values;
  }

  const userId = userIdForDataUpdate(update);
  setFormState(update);
  setWorking(true);

  Axios.post(process.env.GROWFLOW_STORE_DATA_ENDPOINT, {
    data: update,
    userId,
  })
    .then(() => {
      setWorking(false);
    })
    .catch((/*error*/) => {
      setWorking(false);
      // Getting the Error details.
      // TODO: Do something with the error
    });

  return update;
};

export const deleteFormElement = (formStateRef, valueId) => {
  const { values } = formStateRef;
  delete values[valueId];
  formStateRef.values = values;
  handleFormSubmit(formStateRef)();
};

const onlyKeysThatCouldBeInFormStateWhenWizardHasNotGonePastLicenses = [
  'introInformation',
  'licenseInformation',
  'stepStatus',
];

const keys = onlyKeysThatCouldBeInFormStateWhenWizardHasNotGonePastLicenses;

const wizardStepsNotStarted = data =>
  Object.keys(data).every(key => keys.includes(key)) &&
  between(2, keys.length)(Object.keys(data).length);

export const createQueriesFromFormState = formState => ({
  wizardStepsNotStarted: wizardStepsNotStarted(formState),
});

export const createQueriesHook = ({ formState = {} } = allState()) =>
  createQueriesFromFormState(formState);
