import { getFormState, handleFormSubmit } from './formstate';
import { isNotNullOrUndefined, createAndMatch as where } from './criteria';
import { curry } from './funcy';

export const FORM_NAME = 'stepStatus';

export const allState = () => ({
  stepStatusState: getFormState(FORM_NAME),
});

const createStatusMatcher = (stepName, status) =>
  where({
    [stepName]: isNotNullOrUndefined.and(where(status)),
  });

const hasStarted = curry((statusMap, stepName) =>
  createStatusMatcher(stepName, { started: true })(statusMap)
);

const hasCompleted = curry((statusMap, stepName) =>
  createStatusMatcher(stepName, { completed: true })(statusMap)
);

export const createQueries = ({ stepStatusState = {} } = {}) => {
  const statusMap = stepStatusState.values || {};

  return {
    hasStarted: hasStarted(statusMap),
    hasCompleted: hasCompleted(statusMap),
  };
};

const changeStepStatus = ({ stepName, stepStatusState }, status) => {
  const data = {
    id: stepName,
    ...status,
  };
  return handleFormSubmit(stepStatusState)(data, true);
};

export const flagStepStarted = ({ stepStatusState, stepName }) =>
  changeStepStatus(
    { stepName, stepStatusState },
    {
      started: true,
      completed: false,
    }
  );

export const flagStepCompleted = ({ stepStatusState, stepName }) =>
  changeStepStatus(
    { stepName, stepStatusState },
    {
      started: true,
      completed: true,
    }
  );
