import {
  createQueries as createStepStatusQueries,
  flagStepCompleted,
  flagStepStarted,
} from '../utilities/stepStatus';
import Avo from '../avo/Avo';

export const getStepEventTriggers = ({ eventName, stepStatusState }) => {
  const startEventName = `stepStarted${eventName}`;
  const completedEventName = `stepCompleted${eventName}`;
  const started = Avo[startEventName];
  const completed = Avo[completedEventName];
  const queries = createStepStatusQueries({ stepStatusState });

  return {
    started() {
      if (queries.hasStarted(eventName)) return;

      flagStepStarted({ stepName: eventName, stepStatusState });

      started();
    },

    completed() {
      if (queries.hasCompleted(eventName)) return;

      flagStepCompleted({ stepName: eventName, stepStatusState });

      completed();
    },
  };
};
