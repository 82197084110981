import { isNotNullOrUndefined, nonEmptyMap } from './criteria';
import { getFormState } from './formstate';

export const FORM_NAME = 'introInformation';
const hasIntroData = isNotNullOrUndefined.and(nonEmptyMap);

const emptyIntro = {
  companyName: '',
  email: '',
  fullName: '',
  numEmployees: '',
  phone: '',
  state: '',
};

export const allState = () => ({
  introState: getFormState(FORM_NAME),
});

const createQueriesCore = introState => {
  const values = introState || {};
  const unableToSendConfirmation = Object.keys(values).length === 0;

  const fullName = values['fullName'] || '';
  const nameElements = fullName.split(' ');

  const coreDetails = Object.keys(emptyIntro).reduce(
    (acc, key) => ({
      ...acc,
      [key]: values[key] || emptyIntro[key],
    }),
    {}
  );

  const introDataIsAvailable = hasIntroData(introState);

  return {
    introDataIsAvailable,
    firstName: nameElements.length > 0 ? nameElements[0] : '',
    lastName: nameElements.length > 1 ? nameElements[nameElements.length - 1] : '',
    ...coreDetails,
    unableToSendConfirmation,
  };
};

export const createQueriesHook = ({ introState } = allState()) =>
  createQueriesCore(introState.values);

export const createQueriesFromFormState = ({ [FORM_NAME]: introState }) =>
  createQueriesCore(introState);
