const adminDomains = ['@growflow.com', '@getgrowflow.com'];

export const normalizeEmail = email => email.trim().toLowerCase();

const regexCondition = pattern => val => pattern.test(val);

const PASSWORD_CONDITIONS = [
  val => val.length >= 6,
  regexCondition(/[A-Z]{1,}/),
  regexCondition(/[0-9]{1,}/),
  regexCondition(/[a-z]{1,}/),
  regexCondition(/[-+=!@#$%^&*\(\)\[\]\/_:"'<>]{1,}/),
];

export const passwordConstraintDescription =
  'Password must be at least 6 characters and contain 1 uppercase, 1 lowercase and 1 special character';

export const isValidPassword = password =>
  PASSWORD_CONDITIONS.every(condition => condition(password));

export const isGrowFlowEmployee = email =>
  adminDomains.some(val => normalizeEmail(email).includes(val));
