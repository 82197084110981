import Axios from 'axios';

export const registerLicense = async ({
  idToken,
  state,
  leafDataApiKey,
  license,
  password,
  email,
}) => {
  const requestBody = {
    idToken,
    state,
    apiKey: leafDataApiKey,
    license,
    password,
    email,
  };

  try {
    const response = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      query: `mutation registerLicense($licenseData: LicenseRegistrationInput) {
        registerLicense(licenseData: $licenseData)  
      }`,
      variables: {
        licenseData: requestBody,
      },
    });

    const data = response.data.data.registerLicense;

    return JSON.parse(data);
  } catch (err) {
    return { success: false, errors: ['Unexpected error'] };
  }
};
