/* eslint-disable no-console */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import Avo from './src/avo/Avo';
import React from 'react';
import { init as initializeToasts } from './src/toasts';

const { InitialStateProvider } = require('src/components/meta/state');

const avoEnv = {
  development: 'dev',
  production: 'prod',
};

initializeToasts({
  autoClose: 1700,
});

Avo.initAvo({ env: avoEnv[process.env.NODE_ENV] || 'dev' });

export const wrapRootElement = ({ element }) => (
  <InitialStateProvider>{element}</InitialStateProvider>
);

const patchSentry = () => {
  function reduceConsoleArgs(args) {
    let [errorMsg] = args;
    // Make sure errorMsg is either an error or string.
    // It's therefore best to pass in new Error('msg') instead of just 'msg' since
    // that'll give you a stack trace leading up to the creation of that new Error
    // whereas if you just pass in a plain string 'msg', the stack trace will include
    // reportingConsoleError and reportingConsoleCall
    if (!(errorMsg instanceof Error)) {
      // stringify all args as a new Error (which creates a stack trace)
      errorMsg = new Error(
        args.reduce(
          (accumulator, currentValue) =>
            `${accumulator.toString()} ${currentValue.toString()}`,
          ''
        )
      );
    }
    return errorMsg;
  }

  // creating function declarations for better stacktraces (otherwise they'd be anonymous function expressions)
  var oldConsoleError = console.error;
  function reportingConsoleError() {
    // eslint-disable-next-line prefer-rest-params
    var args = Array.prototype.slice.call(arguments);
    Raven.captureException(reduceConsoleArgs(args), { level: 'error' });
    return oldConsoleError.apply(console, args);
  }
  console.error = reportingConsoleError;

  var oldConsoleWarn = console.warn;
  function reportingConsoleWarn() {
    // eslint-disable-next-line prefer-rest-params
    var args = Array.prototype.slice.call(arguments);
    Raven.captureMessage(reduceConsoleArgs(args), { level: 'warning' });
    return oldConsoleWarn.apply(console, args);
  }
  console.warn = reportingConsoleWarn;
};

const ifSentryAvailableThenPatch = () => {
  if (typeof Raven !== 'undefined') {
    patchSentry();
  } else {
    setTimeout(ifSentryAvailableThenPatch, 1000);
  }
};

window.onload = ifSentryAvailableThenPatch;
