import { autoSendSignInEmail, getUser } from './auth';

import Axios from 'axios';

export const getCustomers = async () => {
  const user = getUser();
  if (!user) return;

  try {
    const res = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      operationName: null,
      variables: {},
      query: `{ getCustomers(idToken: "${user.idToken}") }`,
    });
    const data = JSON.parse(res.data.data.getCustomers);
    return data;
  } catch (error) {
    // TODO: Do something with the error
  }
};

export const getCustomer = async email => {
  const user = getUser();
  if (!user) return;

  try {
    const res = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      operationName: null,
      variables: {},
      query: `{ getCustomer(idToken: "${user.idToken}", email: "${email}") }`,
    });
    const data = JSON.parse(res.data.data.getCustomer);
    return data;
  } catch (error) {
    // TODO: Do something with the error
  }
};

export const isCustomer = async email => {
  try {
    const res = await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      operationName: null,
      variables: {},
      query: `{ isCustomer(email: "${email}") }`,
    });
    return JSON.parse(res.data.data.isCustomer);
  } catch (error) {
    return false;
  }
};

export const autoSigninExistingUser = async email => {
  const isExistingCustomer = await isCustomer(email);

  if (isExistingCustomer) autoSendSignInEmail(email);

  return isExistingCustomer;
};
