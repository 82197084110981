import Avo from '../avo/Avo';

export const trackPrinterSaved = ({ id, labelHeight, labelStyleNeither, labelStyleRollOfLabels, labelStyleSheetOfLabels, labelWidth, manufacturer, modelNumber, noPrinterSelected, type }) => {

  Avo.printerAdded({
    id,
    labelHeight,
    labelStyleNeither,
    labelStyleRollOfLabels,
    labelStyleSheetOfLabels,
    labelWidth,
    manufacturer,
    modelNumber,
    noPrinterSelected,
    type
  });
};


export const trackPrinterUpdated = ({ id, labelHeight, labelStyleNeither, labelStyleRollOfLabels, labelStyleSheetOfLabels, labelWidth, manufacturer, modelNumber, noPrinterSelected, type }) => {

  Avo.printerUpdated({
    id,
    labelHeight,
    labelStyleNeither,
    labelStyleRollOfLabels,
    labelStyleSheetOfLabels,
    labelWidth,
    manufacturer,
    modelNumber,
    noPrinterSelected,
    type
  });
};


export const trackPrinterDeleted = ({ id }) => {

  Avo.printerDeleted({
    id,
  });
};