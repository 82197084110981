// Generated by Avo VERSION 61.11.0, PLEASE EDIT WITH CARE
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, AvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  AvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties) {
      console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
    }
    };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "ePkG4KXdB9YDALcxJsfO",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "pCk4SGvwJbFwKjPrKrQr",
    "se": (new Date()).toISOString(),
    "so": "iPhp8tkN9",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "ePkG4KXdB9YDALcxJsfO",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "pCk4SGvwJbFwKjPrKrQr",
    "se": (new Date()).toISOString(),
    "so": "iPhp8tkN9",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "pCk4SGvwJbFwKjPrKrQr",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


var SegmentOnboarding = {
  make: function make(apiKey, options) {
    if (typeof window === 'undefined') { console.warn('window.analytics is not available in Node.js'); return; }
    var analytics = window.analytics = window.analytics || [];
    if (analytics.initialize) return;
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on'
    ];
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function(key, options){
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/'
        + key + '/analytics.min.js';
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics.SNIPPET_VERSION = '4.1.0';

    analytics.load(apiKey, options);
  },

  /* Segment does not have revenue api, @see https://segment.com/docs/spec/track/#properties */
  logEvent: function logEvent(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.track(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },

  setUserProperties: function setUserProperties(userProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userProperties, Object.assign({}, {integrations: integrations}));
  },

  identify: function identify(userId, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userId, {}, Object.assign({}, {integrations: integrations}));
  },

  unidentify: function unidentify(integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(null, {}, Object.assign({}, {integrations: integrations}));
  },

  page: function page(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.page(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },
};

  function assertType(type, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("B1moSRZ_s5", label_ ? 'type' + ': ' + label_ : 'type', type));
    return messages;
  }

  function assertId(id, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("HmwmuxtGJ3", label_ ? 'id' + ': ' + label_ : 'id', id));
    return messages;
  }

  function assertNumberOfEmployees(numberOfEmployees, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("J-6gau8-S6", label_ ? 'numberOfEmployees' + ': ' + label_ : 'numberOfEmployees', numberOfEmployees));
    messages = messages.concat(AvoAssert.assertMin("J-6gau8-S6", label_ ? 'numberOfEmployees' + ': ' + label_ : 'numberOfEmployees', 1, numberOfEmployees));
    return messages;
  }

  function assertState(state, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("JUjt2QXfnn", label_ ? 'state' + ': ' + label_ : 'state', state));
    return messages;
  }

  function assertOptionalPhone(phone, label_) {
    var messages = [];
    if (phone !== undefined && phone !== null) {
      messages = messages.concat(AvoAssert.assertString("JzmK96BiX1", label_ ? 'phone' + ': ' + label_ : 'phone', phone));
    }
    return messages;
  }

  function assertEmail(email, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("K9C1Vpi8Sz", label_ ? 'email' + ': ' + label_ : 'email', email));
    return messages;
  }

  function assertLabelStyleSheetOfLabels(labelStyleSheetOfLabels, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertBool("O-3pNQP6n3", label_ ? 'labelStyleSheetOfLabels' + ': ' + label_ : 'labelStyleSheetOfLabels', labelStyleSheetOfLabels));
    return messages;
  }

  function assertLabelStyleNeither(labelStyleNeither, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertBool("QN2Up_8qX7", label_ ? 'labelStyleNeither' + ': ' + label_ : 'labelStyleNeither', labelStyleNeither));
    return messages;
  }

  function assertLabelStyleRollOfLabels(labelStyleRollOfLabels, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertBool("UsOnPSDseW", label_ ? 'labelStyleRollOfLabels' + ': ' + label_ : 'labelStyleRollOfLabels', labelStyleRollOfLabels));
    return messages;
  }

  function assertManufacturer(manufacturer, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("V6-OIy6tMs", label_ ? 'manufacturer' + ': ' + label_ : 'manufacturer', manufacturer));
    return messages;
  }

  function assertSegmentPageName_(segmentPageName_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-page-segment-name", label_ ? 'Segment: Page Name' + ': ' + label_ : 'Segment: Page Name', segmentPageName_));
    return messages;
  }

  function assertUserId_(userId_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-user-id", label_ ? 'User Id' + ': ' + label_ : 'User Id', userId_));
    return messages;
  }

  function assertLabelWidth(labelWidth, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("bCTggAE__M", label_ ? 'labelWidth' + ': ' + label_ : 'labelWidth', labelWidth));
    return messages;
  }

  function assertCompanyName(companyName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("f5tpmUgyP", label_ ? 'companyName' + ': ' + label_ : 'companyName', companyName));
    return messages;
  }

  function assertModelNumber(modelNumber, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("h4IEVARgsM", label_ ? 'modelNumber' + ': ' + label_ : 'modelNumber', modelNumber));
    return messages;
  }

  function assertLabelHeight(labelHeight, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("pp4uXswsne", label_ ? 'labelHeight' + ': ' + label_ : 'labelHeight', labelHeight));
    return messages;
  }

  function assertNoPrinterSelected(noPrinterSelected, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertBool("w9tkdYiDIp", label_ ? 'noPrinterSelected' + ': ' + label_ : 'noPrinterSelected', noPrinterSelected));
    return messages;
  }

  function assertFullName(fullName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("xhR9-SN2yY", label_ ? 'fullName' + ': ' + label_ : 'fullName', fullName));
    return messages;
  }

  function setup_(options, systemProperties, destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
        SegmentOnboarding.make(
          "4uz4sc98gfgOnN4geRjR7PJinSSbhDDx",
          destinationOptions.segmentOnboarding
        );
      } else {
        SegmentOnboarding.make(
          "ks2Y2Gxy679gU5NpSxbNl2NO8Lg6Ws44",
          destinationOptions.segmentOnboarding
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      console.warn("[avo] ****************************************************");
      console.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
      console.warn("[avo] - Overwriting configuration with noop=false.");
      console.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
      console.warn("[avo] ****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      console.log("[avo] ****************************************************");
      console.log("[avo] Avo is now initialized in noop mode. This means:");
      console.log("[avo] - No events will be sent");
      console.log("[avo] - No network requests are made");
      console.log("[avo] ****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
        SegmentOnboarding.make(
          "4uz4sc98gfgOnN4geRjR7PJinSSbhDDx",
          destinationOptions.segmentOnboarding
        );
      }
      if (__AVO_ENV__ === 'dev') {
        SegmentOnboarding.make(
          "ks2Y2Gxy679gU5NpSxbNl2NO8Lg6Ws44",
          destinationOptions.segmentOnboarding
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * Signed Up: User has clicked on the "Try 1 month free" button and submitted basic contact information to sign up for a GrowFlow Trial.
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.email - Email Address of User/Contact
   * @param {string} properties.phone - Phone # of user signing up
   * @param {string} properties.fullName - Full name of User
   * @param {string} properties.companyName - no description
   * @param {int} properties.numberOfEmployees - # of Employees for a Company
   * @param {string} properties.state - State the Company does business in.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/-zQlGUYPQk}
   */
  function signedUp(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertEmail(properties.email));
      messages = messages.concat(assertOptionalPhone(properties.phone));
      messages = messages.concat(assertFullName(properties.fullName));
      messages = messages.concat(assertCompanyName(properties.companyName));
      messages = messages.concat(assertNumberOfEmployees(properties.numberOfEmployees));
      messages = messages.concat(assertState(properties.state));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Signed Up", Object.keys(properties), [
        "email",
        "phone",
        "fullName",
        "companyName",
        "numberOfEmployees",
        "state"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "-zQlGUYPQk", "a7bfb44a31c2ae77bf4150aa2e53f0312f559908645c75a17d44adab05211eec", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Signed Up", {
        "companyName": properties.companyName,
        "numberOfEmployees": properties.numberOfEmployees,
        "state": properties.state,
        }, {
        "email": properties.email,
        "phone": properties.phone,
        "fullName": properties.fullName,
        });
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("-zQlGUYPQk", "Signed Up", messages, [
        {id: "J-6gau8-S6", name: "numberOfEmployees", value: properties.numberOfEmployees},
        {id: "JUjt2QXfnn", name: "state", value: properties.state},
        {id: "f5tpmUgyP", name: "companyName", value: properties.companyName},
        ], [
        {id:"JzmK96BiX1", name: "phone", value: properties.phone},
        {id:"K9C1Vpi8Sz", name: "email", value: properties.email},
        {id:"xhR9-SN2yY", name: "fullName", value: properties.fullName},
        ]);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Signed Up': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.setUserProperties({
        "email": properties.email,
        "phone": properties.phone,
        "fullName": properties.fullName,
        }, {});
      SegmentOnboarding.logEvent("Signed Up", {
        "companyName": properties.companyName,
        "numberOfEmployees": properties.numberOfEmployees,
        "state": properties.state,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Started Signup: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/z8k9no6kHb}
   */
  function startedSignup(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Started Signup", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "z8k9no6kHb", "5f242a2c6d552106ba9443db8de4879c19caacef5a56c1966c5b46e967323171", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Started Signup", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("z8k9no6kHb", "Started Signup", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Started Signup': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Started Signup", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * View Page: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/GTzKDC-p8E}
   */
  function viewPage(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("View Page", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "GTzKDC-p8E", "e8437b2bc45b77f369f1b0854bc3b8bcbfa5aa3e796ca176e776f1a35ca61df1", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("View Page", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("GTzKDC-p8E", "View Page", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'View Page': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("View Page", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Company Information: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/OdlHQsDbD4}
   */
  function viewedCompanyInformation(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Company Information", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "OdlHQsDbD4", "d4d76fbfcbf69d63e374b8b3dae6cee57c03ea7c559c5a73ec1d09c24fc1cda9", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Company Information", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("OdlHQsDbD4", "Viewed Company Information", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Company Information': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Company Information", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Delivery Vehicles: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/b5cpREJiu8}
   */
  function viewedDeliveryVehicles(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Delivery Vehicles", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "b5cpREJiu8", "fcf4d7e5958f2d685943fe5884532afd3446b8765d44892a5151f00c1348a689", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Delivery Vehicles", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("b5cpREJiu8", "Viewed Delivery Vehicles", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Delivery Vehicles': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Delivery Vehicles", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Employees: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/AOFcWv7lgS}
   */
  function viewedEmployees(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Employees", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "AOFcWv7lgS", "f4609a8bd923e2a51c38f0cb8a451466cac6bb60fbb01bddd9b2a98fa2fe3114", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Employees", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("AOFcWv7lgS", "Viewed Employees", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Employees': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Employees", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Inventory: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/rp-0hwYUBb}
   */
  function viewedInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Inventory", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "rp-0hwYUBb", "bd3cc7f4eb8acfb9f3f7656eab26823c4b7b3b117e7544aa40501f9901993e50", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("rp-0hwYUBb", "Viewed Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Plant Inventory: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/uAocvVNa17}
   */
  function viewedPlantInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Plant Inventory", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "uAocvVNa17", "fb447fb8d3b1967707a49bb4f0f18a54f15b00f03439e65a99a75a64097fa3a3", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Plant Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("uAocvVNa17", "Viewed Plant Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Plant Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Plant Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Plants: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/kgw9hhxW5D}
   */
  function viewedPlants(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Plants", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "kgw9hhxW5D", "17bba273a5ee62cbddde3bc93a2192e078fae9964cbf3c6a53d77ce2fe1aa4e4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Plants", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("kgw9hhxW5D", "Viewed Plants", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Plants': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Plants", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Printers and Labels: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/KfEbwistac}
   */
  function viewedPrintersAndLabels(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Printers and Labels", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "KfEbwistac", "71a92c19c6d5ef6f975863e2ea65529f63957a5a5ad0bf1a68701a22b82d94e8", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Printers and Labels", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("KfEbwistac", "Viewed Printers and Labels", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Printers and Labels': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Printers and Labels", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Rooms: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/jKhtocPxOi}
   */
  function viewedRooms(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Rooms", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "jKhtocPxOi", "f6c57c60f04c67ef9cedc265d2686d847ac528fec2efb1785508880c6358dea2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Rooms", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("jKhtocPxOi", "Viewed Rooms", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Rooms': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Rooms", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Strains: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/Erw6rBvQSf}
   */
  function viewedStrains(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Strains", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Erw6rBvQSf", "c7275bfad261699c42e13b7f71a9de36bc65170f103dcb3bb9bc70c20032e392", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Strains", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Erw6rBvQSf", "Viewed Strains", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Strains': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Strains", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Completion: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/q4BxViuCMs}
   */
  function viewedCompletion(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Completion", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "q4BxViuCMs", "537ac4b8af71ea78cb1345ecd3323e7fb2284860fa5eddbd78863b050fdd5534", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Completion", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("q4BxViuCMs", "Viewed Completion", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Completion': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Completion", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Completed Signup Part 1: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   * @param {string} properties.companyName - no description
   * @param {string} properties.state - State the Company does business in.
   * @param {string} properties.email - Email Address of User/Contact
   * @param {string} properties.phone - Phone # of user signing up
   * @param {string} properties.fullName - Full name of User
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/3uUewsgrox}
   */
  function completedSignupPart1(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(assertCompanyName(properties.companyName));
      messages = messages.concat(assertState(properties.state));
      messages = messages.concat(assertEmail(properties.email));
      messages = messages.concat(assertOptionalPhone(properties.phone));
      messages = messages.concat(assertFullName(properties.fullName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Completed Signup Part 1", Object.keys(properties), [
        "userId_",
        "companyName",
        "state",
        "email",
        "phone",
        "fullName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3uUewsgrox", "b03a3e5db831482408bdba30aae5101b3b692f076ed78696d080c7b025ddfe4e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Completed Signup Part 1", {
        "companyName": properties.companyName,
        "state": properties.state,
        }, {
        "email": properties.email,
        "phone": properties.phone,
        "fullName": properties.fullName,
        });
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3uUewsgrox", "Completed Signup Part 1", messages, [
        {id: "JUjt2QXfnn", name: "state", value: properties.state},
        {id: "f5tpmUgyP", name: "companyName", value: properties.companyName},
        ], [
        {id:"JzmK96BiX1", name: "phone", value: properties.phone},
        {id:"K9C1Vpi8Sz", name: "email", value: properties.email},
        {id:"xhR9-SN2yY", name: "fullName", value: properties.fullName},
        ]);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Completed Signup Part 1': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.identify(properties.userId_, {});
      SegmentOnboarding.setUserProperties({
        "email": properties.email,
        "phone": properties.phone,
        "fullName": properties.fullName,
        }, {});
      SegmentOnboarding.logEvent("Completed Signup Part 1", {
        "companyName": properties.companyName,
        "state": properties.state,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Confirm Account: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/AjoSrOyLoV}
   */
  function viewedConfirmAccount(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Confirm Account", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "AjoSrOyLoV", "d34f9dc4e18c2259e44cb8bc2a36b827ecca43a0d083521cb505f018107c356c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Confirm Account", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("AjoSrOyLoV", "Viewed Confirm Account", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Confirm Account': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Confirm Account", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Viewed Signup: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/NUbA8WqMVF}
   */
  function viewedSignup(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Viewed Signup", Object.keys(properties), [
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "NUbA8WqMVF", "819c6196855d9e2f9cbda27fdf65830aa78e8aa3d5964fb24c88d0c7e4d909fc", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Viewed Signup", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("NUbA8WqMVF", "Viewed Signup", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Viewed Signup': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.page(properties.segmentPageName_, {}, {});
      SegmentOnboarding.logEvent("Viewed Signup", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Company Information: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/TZpXnRJDzq}
   */
  function stepStartedCompanyInformation(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Company Information", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "TZpXnRJDzq", "eedd65a869f48831ef49c8acd7b1d99b65779f8917a661093be8bc57fb2d8db7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Company Information", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("TZpXnRJDzq", "Step Started - Company Information", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Company Information': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Company Information", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Company Information: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/sbcsrQQoWY}
   */
  function stepCompletedCompanyInformation(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Company Information", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "sbcsrQQoWY", "cfe925133f010fe4b6ad885c138aff1fd0931db470e57d008192fd345886d3b5", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Company Information", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("sbcsrQQoWY", "Step Completed - Company Information", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Company Information': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Company Information", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Delivery Vehicles: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/bH-AEmfMRL}
   */
  function stepStartedDeliveryVehicles(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Delivery Vehicles", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "bH-AEmfMRL", "1903ad9a4418e1c7751d8658dfa715f85d5d1cf83f33d50ed35b74e5835754ea", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Delivery Vehicles", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("bH-AEmfMRL", "Step Started - Delivery Vehicles", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Delivery Vehicles': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Delivery Vehicles", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Delivery Vehicles: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/XjL85pY0AI}
   */
  function stepCompletedDeliveryVehicles(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Delivery Vehicles", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "XjL85pY0AI", "20810c5a466ffb14cc36a8855a06f23161790c460acd6ba2ec9217e1bbcf5f6c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Delivery Vehicles", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("XjL85pY0AI", "Step Completed - Delivery Vehicles", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Delivery Vehicles': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Delivery Vehicles", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Employees: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/tGR3OC3x9h}
   */
  function stepStartedEmployees(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Employees", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "tGR3OC3x9h", "69659e60864e04e0c6b910c67559d7f0f153832a33e56b7aaaf4b02f5920808b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Employees", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("tGR3OC3x9h", "Step Started - Employees", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Employees': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Employees", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Employees: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/8uOj8NjQCe}
   */
  function stepCompletedEmployees(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Employees", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "8uOj8NjQCe", "64f7ad7abdf7d46846d331dd8ab3d2bfaf07ba365f52431d3c9ebc26d692725a", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Employees", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("8uOj8NjQCe", "Step Completed - Employees", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Employees': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Employees", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Plants: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/FYZtO55XNH}
   */
  function stepStartedPlants(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Plants", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FYZtO55XNH", "204ad237e781c3d25bdb8af1c94aa9ad90c2f062858c097f49fadc9b7e7122ce", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Plants", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FYZtO55XNH", "Step Started - Plants", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Plants': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Plants", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Plants: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/IXYsJcLY6p}
   */
  function stepCompletedPlants(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Plants", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "IXYsJcLY6p", "00ce98a88555b992c5c5858d1396749cdc7b912eb31d3c4c5350a478a4fd37cb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Plants", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("IXYsJcLY6p", "Step Completed - Plants", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Plants': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Plants", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Plant Inventory: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/hVPoAG_8uO}
   */
  function stepStartedPlantInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Plant Inventory", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "hVPoAG_8uO", "d6cebf532147b0c98eeff12e0c8baffa780176086c418b200300c8e2cebb6c48", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Plant Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("hVPoAG_8uO", "Step Started - Plant Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Plant Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Plant Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Plant Inventory: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/IBY7Pj8zam}
   */
  function stepCompletedPlantInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Plant Inventory", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "IBY7Pj8zam", "e4eeae13e98738bc8893e97de4b55927708a05e703524b66f44d54d67e8c828b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Plant Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("IBY7Pj8zam", "Step Completed - Plant Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Plant Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Plant Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Inventory: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/uUe9AQJXd5}
   */
  function stepStartedInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Inventory", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "uUe9AQJXd5", "8b709c9c5e18d95d5c7f8bc61fc0e1e1f6f73ac94ba8df08a0f43384b35c78a0", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("uUe9AQJXd5", "Step Started - Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Inventory: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/KETG4_4zzY}
   */
  function stepCompletedInventory(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Inventory", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "KETG4_4zzY", "556b1c2edb2b06490e231fead09db5c0f9dc66deb64c6e461cb33036272b4f4b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Inventory", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("KETG4_4zzY", "Step Completed - Inventory", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Inventory': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Inventory", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Printers and Labels: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/OWxXYXmbGO}
   */
  function stepStartedPrintersAndLabels(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Printers and Labels", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "OWxXYXmbGO", "7a261c0eef0c24e609d0e6785deb37519eec16ca2b00d96d313ceabec9bceda8", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Printers and Labels", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("OWxXYXmbGO", "Step Started - Printers and Labels", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Printers and Labels': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Printers and Labels", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Printers and Labels: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/YVK2mFRGSS}
   */
  function stepCompletedPrintersAndLabels(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Printers and Labels", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "YVK2mFRGSS", "c98c6692dfc868189a4f1d9279932ecc537da4707ecb9a7c0ada149ae8af10c0", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Printers and Labels", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("YVK2mFRGSS", "Step Completed - Printers and Labels", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Printers and Labels': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Printers and Labels", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Strains: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/Nm253HanG0}
   */
  function stepStartedStrains(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Strains", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Nm253HanG0", "34581ae0302f091ff3dd45db16db5bae7581ef3be0d815d3a991379611d35baa", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Strains", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Nm253HanG0", "Step Started - Strains", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Strains': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Strains", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Strains: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/Gf4vr6E_hm}
   */
  function stepCompletedStrains(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Strains", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Gf4vr6E_hm", "6ab2132989eae186825d4734d84e239609eab4dde2ee4de962ebfa8e18b6c41c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Strains", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Gf4vr6E_hm", "Step Completed - Strains", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Strains': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Strains", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Started - Rooms: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/QO9Oy0bSAr}
   */
  function stepStartedRooms(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Started - Rooms", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "QO9Oy0bSAr", "d43c8e7b7480489c666440446b9c2be125a20dafa5abd2d2cdb3c2c7e1c2e86e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Started - Rooms", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("QO9Oy0bSAr", "Step Started - Rooms", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Started - Rooms': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Started - Rooms", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Step Completed - Rooms: No description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/FD1twgOPn6}
   */
  function stepCompletedRooms(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Step Completed - Rooms", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FD1twgOPn6", "ad81885be66f60dc71d5a9f5f6dd07b20f8db8b232b5ba62d4a5922c9b393801", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Step Completed - Rooms", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FD1twgOPn6", "Step Completed - Rooms", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Step Completed - Rooms': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Step Completed - Rooms", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * User Data Initialized: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/lOH6IWYVi5}
   */
  function userDataInitialized(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("User Data Initialized", Object.keys(properties), [
        "userId_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "lOH6IWYVi5", "eb5a7680aa65d8e4ee3e2e62513a0725330132235929a01cd6b57e66f8512b6f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("User Data Initialized", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("lOH6IWYVi5", "User Data Initialized", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'User Data Initialized': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.identify(properties.userId_, {});
      SegmentOnboarding.logEvent("User Data Initialized", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Printer Added: Occurs when the user saves a printer in the onboarding app.
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.id - no description
   * @param {string} properties.labelHeight - no description
   * @param {bool} properties.labelStyleNeither - no description
   * @param {bool} properties.labelStyleRollOfLabels - no description
   * @param {bool} properties.labelStyleSheetOfLabels - no description
   * @param {string} properties.labelWidth - no description
   * @param {string} properties.manufacturer - no description
   * @param {string} properties.modelNumber - no description
   * @param {bool} properties.noPrinterSelected - no description
   * @param {string} properties.type - no description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/9Xi_ab20-U}
   */
  function printerAdded(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertId(properties.id));
      messages = messages.concat(assertLabelHeight(properties.labelHeight));
      messages = messages.concat(assertLabelStyleNeither(properties.labelStyleNeither));
      messages = messages.concat(assertLabelStyleRollOfLabels(properties.labelStyleRollOfLabels));
      messages = messages.concat(assertLabelStyleSheetOfLabels(properties.labelStyleSheetOfLabels));
      messages = messages.concat(assertLabelWidth(properties.labelWidth));
      messages = messages.concat(assertManufacturer(properties.manufacturer));
      messages = messages.concat(assertModelNumber(properties.modelNumber));
      messages = messages.concat(assertNoPrinterSelected(properties.noPrinterSelected));
      messages = messages.concat(assertType(properties.type));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Printer Added", Object.keys(properties), [
        "id",
        "labelHeight",
        "labelStyleNeither",
        "labelStyleRollOfLabels",
        "labelStyleSheetOfLabels",
        "labelWidth",
        "manufacturer",
        "modelNumber",
        "noPrinterSelected",
        "type"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "9Xi_ab20-U", "11541892e66c87baca77e9dee10db564299100633609cb871f32febf0715a348", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Printer Added", {
        "id": properties.id,
        "labelHeight": properties.labelHeight,
        "labelStyleNeither": properties.labelStyleNeither,
        "labelStyleRollOfLabels": properties.labelStyleRollOfLabels,
        "labelStyleSheetOfLabels": properties.labelStyleSheetOfLabels,
        "labelWidth": properties.labelWidth,
        "manufacturer": properties.manufacturer,
        "modelNumber": properties.modelNumber,
        "noPrinterSelected": properties.noPrinterSelected,
        "type": properties.type,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("9Xi_ab20-U", "Printer Added", messages, [
        {id: "B1moSRZ_s5", name: "type", value: properties.type},
        {id: "HmwmuxtGJ3", name: "id", value: properties.id},
        {id: "O-3pNQP6n3", name: "labelStyleSheetOfLabels", value: properties.labelStyleSheetOfLabels},
        {id: "QN2Up_8qX7", name: "labelStyleNeither", value: properties.labelStyleNeither},
        {id: "UsOnPSDseW", name: "labelStyleRollOfLabels", value: properties.labelStyleRollOfLabels},
        {id: "V6-OIy6tMs", name: "manufacturer", value: properties.manufacturer},
        {id: "bCTggAE__M", name: "labelWidth", value: properties.labelWidth},
        {id: "h4IEVARgsM", name: "modelNumber", value: properties.modelNumber},
        {id: "pp4uXswsne", name: "labelHeight", value: properties.labelHeight},
        {id: "w9tkdYiDIp", name: "noPrinterSelected", value: properties.noPrinterSelected},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Printer Added': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Printer Added", {
        "id": properties.id,
        "labelHeight": properties.labelHeight,
        "labelStyleNeither": properties.labelStyleNeither,
        "labelStyleRollOfLabels": properties.labelStyleRollOfLabels,
        "labelStyleSheetOfLabels": properties.labelStyleSheetOfLabels,
        "labelWidth": properties.labelWidth,
        "manufacturer": properties.manufacturer,
        "modelNumber": properties.modelNumber,
        "noPrinterSelected": properties.noPrinterSelected,
        "type": properties.type,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Printer Updated: Occurs when the user updates an existing printer in the onboarding app.
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.id - no description
   * @param {string} properties.labelHeight - no description
   * @param {bool} properties.labelStyleNeither - no description
   * @param {bool} properties.labelStyleRollOfLabels - no description
   * @param {bool} properties.labelStyleSheetOfLabels - no description
   * @param {string} properties.labelWidth - no description
   * @param {string} properties.manufacturer - no description
   * @param {string} properties.modelNumber - no description
   * @param {bool} properties.noPrinterSelected - no description
   * @param {string} properties.type - no description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/tzw5zvT-Sd}
   */
  function printerUpdated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertId(properties.id));
      messages = messages.concat(assertLabelHeight(properties.labelHeight));
      messages = messages.concat(assertLabelStyleNeither(properties.labelStyleNeither));
      messages = messages.concat(assertLabelStyleRollOfLabels(properties.labelStyleRollOfLabels));
      messages = messages.concat(assertLabelStyleSheetOfLabels(properties.labelStyleSheetOfLabels));
      messages = messages.concat(assertLabelWidth(properties.labelWidth));
      messages = messages.concat(assertManufacturer(properties.manufacturer));
      messages = messages.concat(assertModelNumber(properties.modelNumber));
      messages = messages.concat(assertNoPrinterSelected(properties.noPrinterSelected));
      messages = messages.concat(assertType(properties.type));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Printer Updated", Object.keys(properties), [
        "id",
        "labelHeight",
        "labelStyleNeither",
        "labelStyleRollOfLabels",
        "labelStyleSheetOfLabels",
        "labelWidth",
        "manufacturer",
        "modelNumber",
        "noPrinterSelected",
        "type"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "tzw5zvT-Sd", "8f3dc4a95324bc3dd4948778eeb5366a0bb511f05a218d5dc4defb09ff12e4a8", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Printer Updated", {
        "id": properties.id,
        "labelHeight": properties.labelHeight,
        "labelStyleNeither": properties.labelStyleNeither,
        "labelStyleRollOfLabels": properties.labelStyleRollOfLabels,
        "labelStyleSheetOfLabels": properties.labelStyleSheetOfLabels,
        "labelWidth": properties.labelWidth,
        "manufacturer": properties.manufacturer,
        "modelNumber": properties.modelNumber,
        "noPrinterSelected": properties.noPrinterSelected,
        "type": properties.type,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("tzw5zvT-Sd", "Printer Updated", messages, [
        {id: "B1moSRZ_s5", name: "type", value: properties.type},
        {id: "HmwmuxtGJ3", name: "id", value: properties.id},
        {id: "O-3pNQP6n3", name: "labelStyleSheetOfLabels", value: properties.labelStyleSheetOfLabels},
        {id: "QN2Up_8qX7", name: "labelStyleNeither", value: properties.labelStyleNeither},
        {id: "UsOnPSDseW", name: "labelStyleRollOfLabels", value: properties.labelStyleRollOfLabels},
        {id: "V6-OIy6tMs", name: "manufacturer", value: properties.manufacturer},
        {id: "bCTggAE__M", name: "labelWidth", value: properties.labelWidth},
        {id: "h4IEVARgsM", name: "modelNumber", value: properties.modelNumber},
        {id: "pp4uXswsne", name: "labelHeight", value: properties.labelHeight},
        {id: "w9tkdYiDIp", name: "noPrinterSelected", value: properties.noPrinterSelected},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Printer Updated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Printer Updated", {
        "id": properties.id,
        "labelHeight": properties.labelHeight,
        "labelStyleNeither": properties.labelStyleNeither,
        "labelStyleRollOfLabels": properties.labelStyleRollOfLabels,
        "labelStyleSheetOfLabels": properties.labelStyleSheetOfLabels,
        "labelWidth": properties.labelWidth,
        "manufacturer": properties.manufacturer,
        "modelNumber": properties.modelNumber,
        "noPrinterSelected": properties.noPrinterSelected,
        "type": properties.type,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Printer Deleted: Occurs when the user removes a printer from the onboarding app.
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.id - no description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/yMN-pIMKnu}
   */
  function printerDeleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertId(properties.id));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Printer Deleted", Object.keys(properties), [
        "id"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "yMN-pIMKnu", "c9f03a868750622abd477e8523a2b759c200145ae7324357ba88d9cc947f5e73", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      AvoLogger.logEventSent("Printer Deleted", {
        "id": properties.id,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("yMN-pIMKnu", "Printer Deleted", messages, [
        {id: "HmwmuxtGJ3", name: "id", value: properties.id},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Printer Deleted': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      // destination SegmentOnboarding
      SegmentOnboarding.logEvent("Printer Deleted", {
        "id": properties.id,
        }, {});
    } else {
      // do nothing
    }
  }

  exports.signedUp = signedUp;
  exports.startedSignup = startedSignup;
  exports.viewPage = viewPage;
  exports.viewedCompanyInformation = viewedCompanyInformation;
  exports.viewedDeliveryVehicles = viewedDeliveryVehicles;
  exports.viewedEmployees = viewedEmployees;
  exports.viewedInventory = viewedInventory;
  exports.viewedPlantInventory = viewedPlantInventory;
  exports.viewedPlants = viewedPlants;
  exports.viewedPrintersAndLabels = viewedPrintersAndLabels;
  exports.viewedRooms = viewedRooms;
  exports.viewedStrains = viewedStrains;
  exports.viewedCompletion = viewedCompletion;
  exports.completedSignupPart1 = completedSignupPart1;
  exports.viewedConfirmAccount = viewedConfirmAccount;
  exports.viewedSignup = viewedSignup;
  exports.stepStartedCompanyInformation = stepStartedCompanyInformation;
  exports.stepCompletedCompanyInformation = stepCompletedCompanyInformation;
  exports.stepStartedDeliveryVehicles = stepStartedDeliveryVehicles;
  exports.stepCompletedDeliveryVehicles = stepCompletedDeliveryVehicles;
  exports.stepStartedEmployees = stepStartedEmployees;
  exports.stepCompletedEmployees = stepCompletedEmployees;
  exports.stepStartedPlants = stepStartedPlants;
  exports.stepCompletedPlants = stepCompletedPlants;
  exports.stepStartedPlantInventory = stepStartedPlantInventory;
  exports.stepCompletedPlantInventory = stepCompletedPlantInventory;
  exports.stepStartedInventory = stepStartedInventory;
  exports.stepCompletedInventory = stepCompletedInventory;
  exports.stepStartedPrintersAndLabels = stepStartedPrintersAndLabels;
  exports.stepCompletedPrintersAndLabels = stepCompletedPrintersAndLabels;
  exports.stepStartedStrains = stepStartedStrains;
  exports.stepCompletedStrains = stepCompletedStrains;
  exports.stepStartedRooms = stepStartedRooms;
  exports.stepCompletedRooms = stepCompletedRooms;
  exports.userDataInitialized = userDataInitialized;
  exports.printerAdded = printerAdded;
  exports.printerUpdated = printerUpdated;
  exports.printerDeleted = printerDeleted;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["signedUp","startedSignup","viewPage","viewedCompanyInformation","viewedDeliveryVehicles","viewedEmployees","viewedInventory","viewedPlantInventory","viewedPlants","viewedPrintersAndLabels","viewedRooms","viewedStrains","viewedCompletion","completedSignupPart1","viewedConfirmAccount","viewedSignup","stepStartedCompanyInformation","stepCompletedCompanyInformation","stepStartedDeliveryVehicles","stepCompletedDeliveryVehicles","stepStartedEmployees","stepCompletedEmployees","stepStartedPlants","stepCompletedPlants","stepStartedPlantInventory","stepCompletedPlantInventory","stepStartedInventory","stepCompletedInventory","stepStartedPrintersAndLabels","stepCompletedPrintersAndLabels","stepStartedStrains","stepCompletedStrains","stepStartedRooms","stepCompletedRooms","userDataInitialized","printerAdded","printerUpdated","printerDeleted"]
