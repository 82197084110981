export const omit = (keys = [], target = {}) => {
  let omissions = {};
  let result = {};

  for (let index = 0; index < keys.length; index++) omissions[keys[index]] = true;

  for (let prop in target) {
    if (!omissions.hasOwnProperty(prop)) {
      result[prop] = target[prop];
    }
  }

  return result;
};

export const noOp = args => args;

export const curry = fn => (...args) =>
  args.length >= fn.length
    ? fn(...args)
    : curry((...remaining) => fn(...args, ...remaining));
