import Axios from 'axios';
/* eslint-disable no-console */

export const createAccount = async ({
  state,
  companyName,
  fullName,
  email,
  phone,
  password,
  licenses,
}) => {
  const requestBody = {
    companyName,
    state: state.toLowerCase(),
    name: fullName,
    email,
    phone,
    password,
    licenses,
  };

  try {
    await Axios.post(process.env.GROWFLOW_READ_DATA_ENDPOINT, {
      query: `mutation createLegacyAccount($accountData: NewAccountDetailsInput) {
        createLegacyAccount(accountData: $accountData)
      }`,
      variables: {
        accountData: requestBody,
      },
    });
  } catch (err) {
    console.log('Error creating legacy account');
    console.error(err);
  }
};
