import Avo from '../avo/Avo';
import Axios from 'axios';

const publishSignupPart1Event = async ({ email, phone, fullName, companyName, state }) =>
  Avo.completedSignupPart1({
    userId_: email,
    email,
    phone,
    fullName,
    companyName,
    state,
  });

const triggerSignupPart1WebHook = async ({
  email,
  phone,
  fullName,
  companyName,
  state,
}) => {
  const message = `Onboarding Wizard Page 1 Complete (${state}): \n${fullName} signed up ${companyName} (${email} - ${phone}) in *${state}*.`;

  return await Axios.post(
    process.env.SIGNUP_COMPLETE_WEBHOOK_URL,
    {
      text: message,
      username: 'Onboarding Wizard Signups Page 1',
      channel: '#signup_started',
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

export const completedSignupPart1 = ({ email, phone, fullName, companyName, state }) => {
  const details = { email, phone, fullName, companyName, state };
  const promises = [publishSignupPart1Event(details), triggerSignupPart1WebHook(details)];

  return Promise.all(promises);
};
