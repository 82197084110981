import {
  isEmpty,
  isNotNullOrUndefined,
  isNullOrUndefined,
  not,
  createAndMatch as where,
} from './criteria';

import { WebAuth } from 'auth0-js';
import { getCustomer } from './customers';
import jwt from 'jsonwebtoken';
import { navigate } from 'gatsby';
import { noOp } from './funcy';
import { normalizeEmail } from '../../functions/users';
import store from 'store';
import { useEffect } from 'react';
import { useGlobal } from 'reactn';

/*
  SDK docs for WebAuth
  https://auth0.com/docs/libraries/auth0js/v9
*/
// Auth0 uses a nonce/state to prevent cross-browser attacks
// That breaks passwordless from a different browser though...
// This login isn't that sensitive, so we disable that protection by using
// a static nonce

const STATIC_NONCE = '1234';

const auth0 = new WebAuth({
  domain: process.env.AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENT_ID,
  redirectUri: `${process.env.BASE_URL}/auth/`,
  responseType: 'token id_token',
  scope: 'openid profile',
  nonce: STATIC_NONCE,
});

export const autoSendSignInEmail = email => {
  navigate('/auth', {
    state: { autoSend: true, email: normalizeEmail(email) },
  });
};

const impersonatingCriteria = isNotNullOrUndefined.and(
  where({
    impersonatedUser: not(isNullOrUndefined.or(isEmpty)),
  })
);

export const impersonateUser = () => {
  const [global, setGlobal] = useGlobal();

  return async email => {
    store.set('impersonatedUser', normalizeEmail(email));
    const userState = await getCustomer(normalizeEmail(email));
    setGlobal({ ...global, impersonatedFormState: userState });
  };
};

export const stopImpersonating = () => {
  const [global, setGlobal] = useGlobal();

  return () => {
    store.set('impersonatedUser', '');
    setGlobal({ ...global, impersonatedFormState: {} });
  };
};

export const sendLoginEmail = email => {
  auth0.passwordlessStart(
    {
      connection: 'email',
      send: 'link',
      email: normalizeEmail(email),
    },
    noOp
  );
};

function secondsToEpochTime(secs) {
  return Math.round(secs * 1000);
}

export const logout = () => {
  store.remove('user');
  store.remove('auth');
  store.remove('impersonatedUser');
  auth0.logout({ returnTo: window.location.origin });
};

export function useCheckAuthTokenExpired(props = []) {
  useEffect(() => {
    const authStorage = store.get('auth');
    const authToken = authStorage && authStorage.idToken;
    const dateNow = Date.now();
    const decodedToken = jwt.decode(authToken);
    const tokenExpTime = secondsToEpochTime(decodedToken && decodedToken.exp);
    if (authStorage && dateNow > tokenExpTime) {
      // eslint-disable-next-line no-console
      console.error('Logging the user out due to expired token');
      logout();
      return false;
    }
  }, props);
}

export const getUser = () => {
  // TODO -- Revalidate these tokens now and then?
  const user = store.get('user');
  const impersonatedUser = store.get('impersonatedUser');

  if (user) {
    return {
      ...store.get('user'),
      ...store.get('auth'),
      impersonatedUser,
    };
  }
  return null;
};

export const isImpersonatingUser = () => impersonatingCriteria(getUser());

export const checkAuthToken = cb => {
  const hash = window !== undefined ? window.location.hash : undefined;
  if (hash) {
    // This is part of our "allow cross-browser logins" hack
    const hashVals = hash.split('&').reduce((obj, kv) => {
      const [key, value] = kv.split('=');
      return Object.assign(obj, { [key]: value });
    }, {});
    const { state } = hashVals;

    auth0.parseHash({ hash, nonce: STATIC_NONCE, state }, (err, authResult) => {
      if (err) {
        return console.error(JSON.stringify(err)); // eslint-disable-line no-console
      }

      store.set('auth', authResult);
      store.set('user', authResult.idTokenPayload);
      cb(authResult.idTokenPayload);
    });
  }
};
