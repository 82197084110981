// eslint-disable-next-line no-use-before-define
export const condition = criteria => extend(item => criteria(item));

const extend = matcher => {
  // eslint-disable-next-line no-use-before-define
  matcher.and = other => and(matcher, other);

  // eslint-disable-next-line no-use-before-define
  matcher.or = other => or(matcher, other);

  return matcher;
};

const combineAnd = (first, second) => condition(item => first(item) && second(item));

const combineOr = (first, second) => condition(item => first(item) || second(item));

const combineMatchers = (combineStrategy, initialMatcher) => (...criterion) =>
  extend(
    criterion.reduce((acc, criteria) => combineStrategy(acc, criteria), initialMatcher)
  );

export const neverMatches = condition(() => false);

export const alwaysMatches = condition(() => true);

export const and = combineMatchers(combineAnd, alwaysMatches);

export const or = combineMatchers(combineOr, neverMatches);

export const not = other => extend((...args) => !other(...args));

export const equalTo = condition(value => condition(item => Object.is(value, item)));

export const isArray = condition(Array.isArray);

export const isTrue = equalTo(true);

export const isFalse = equalTo(false);

export const isUndefined = equalTo(undefined);

export const isNull = equalTo(null);

export const isNullOrUndefined = isNull.or(isUndefined);

export const isNotNullOrUndefined = not(isNullOrUndefined);

export const isType = type => condition(item => typeof item === type);

export const isString = isType('string');

export const isFunction = isType('function');

export const isObject = isNotNullOrUndefined.and(not(isArray)).and(isType('object'));

export const isEmpty = isString.and(item => item.trim() === '');

export const greaterThan = val => condition(item => item > val);

export const between = (start, end) => condition(val => val >= start && val <= end);

const createAttributeMatcher = (key, matcher) =>
  condition(item => matcher(item[key], item));

const createMatcher = (combinationStrategy, initialMatcher) => criteriaMap =>
  Object.entries(criteriaMap).reduce(
    (acc, [key, criteriaBuilderOrValue]) =>
      combinationStrategy(
        acc,
        createAttributeMatcher(
          key,
          isFunction(criteriaBuilderOrValue)
            ? criteriaBuilderOrValue
            : equalTo(criteriaBuilderOrValue)
        )
      ),
    initialMatcher
  );

export const createOrMatch = createMatcher(combineOr, neverMatches);

export const createAndMatch = createMatcher(combineAnd, alwaysMatches);

export const emptyArray = isArray.and(
  createAndMatch({
    length: 0,
  })
);

export const nonEmptyArray = isArray.and(
  createAndMatch({
    length: greaterThan(0),
  })
);

export const emptyMap = isObject.and(item => emptyArray(Object.keys(item)));

export const nonEmptyMap = isObject.and(item => nonEmptyArray(Object.keys(item)));
