import Avo from '../avo/Avo';
import Axios from 'axios';
import { createAccount as createLegacyAccount } from '../legacyIntegration';

const slackMessageCopy = ({
  companyName,
  email,
  fullName,
  phone,
  numberOfEmployees,
  state,
  licenses,
  wantsToUseGrowFlowProduct,
  wantsToUseRetailProduct,
}) => {
  const licenseStr = (licenses || []).join(', ');

  const products = [];
  if (wantsToUseGrowFlowProduct) {
    products.push('Grow Wholesale');
  }
  if (wantsToUseRetailProduct) {
    products.push('Retail');
  }
  if (products.length === 0) {
    products.push('None Selected');
  }
  return {
    newSignup: `New Company Signup in ${state}`,
    company: `Company Name: *${companyName} (${email})*`,
    product: `Product(s): *${products.join(', ')}*`,
    employees: `# Employees: *${numberOfEmployees}*`,
    name: `Name: *${fullName}*`,
    phone: `Phone Number: *${phone}*`,
    state: `State: *${state}*`,
    licenses: `Licenses: *${licenseStr}*`,
  };
};

const buildSlackMessage = messages =>
  Object.entries(messages)
    .reduce((acc, [, val]) => acc.concat(val), [])
    .join('\n');

const triggerSignupCompleteWebHook = async ({
  companyName,
  email,
  fullName,
  phone,
  numberOfEmployees,
  state,
  licenses,
  wantsToUseGrowFlowProduct,
  wantsToUseRetailProduct,
}) => {
  const message = buildSlackMessage(
    slackMessageCopy({
      companyName,
      email,
      fullName,
      phone,
      numberOfEmployees,
      state,
      licenses,
      wantsToUseGrowFlowProduct,
      wantsToUseRetailProduct,
    })
  );

  return await Axios.post(
    process.env.SIGNUP_COMPLETE_WEBHOOK_URL,
    {
      text: message,
      username: 'Onboarding Wizard',
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

const publishSignedUpEvent = async ({
  email,
  phone,
  fullName,
  companyName,
  numberOfEmployees,
  state,
}) => {
  Avo.signedUp({
    email,
    phone,
    fullName,
    companyName,
    numberOfEmployees: parseInt(numberOfEmployees),
    state,
  });
};

export const signedUp = async ({
  unableToSendConfirmation,
  email,
  phone,
  fullName,
  password,
  companyName,
  numEmployees,
  state,
  licenses,
  wantsToUseGrowFlowProduct,
  wantsToUseRetailProduct,
}) => {
  if (unableToSendConfirmation) return;

  const details = {
    email,
    phone,
    fullName,
    companyName,
    numberOfEmployees: numEmployees,
    state,
    licenses,
    password,
    wantsToUseGrowFlowProduct,
    wantsToUseRetailProduct,
  };

  const promises = [publishSignedUpEvent(details), triggerSignupCompleteWebHook(details)];

  if (wantsToUseGrowFlowProduct) {
    promises.push(createLegacyAccount(details));
  }

  return Promise.all(promises);
};

export const __test__ = {
  buildSlackMessage,
  slackMessageCopy,
  publishSignedUpEvent,
  triggerSignupCompleteWebHook,
  createLegacyAccount,
};
