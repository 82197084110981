import Avo from '../avo/Avo';

export const viewedPage = page => {
  const eventName = `viewed${page}`;
  if (page !== 'Index') {
    Avo[eventName]({
      segmentPageName_: page,
    });
  } else {
    Avo.viewPage({
      segmentPageName_: 'Index',
    });
  }
};
